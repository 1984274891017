import React from 'react';
import { FastField } from 'formik';
import { fieldDisplayModes } from '../../../../../constants/fieldDisplayModes';
import { DefaultAttributesGroup } from '../../../../form/DefaultAttributesGroup';
import { ModalSection } from '../../../../modals/ModalSection';
import { TextField } from '../../../../fields/TextField';
import { AsyncSelectorField } from '../../../../fields/AsyncSelectorField';
import { isDefined, defaultHandleSubmit } from '../../../../../services/utils';
import { PresentationFields } from './presentationFields';
import { htmlOptionRenderer } from '../../../../fields/selectorOptionRenderers/htmlOptionRenderer';
import { Loader } from '../../../../Loader';
import { FormFooter, FormFooterRightPart, FormFooterLeftPart } from '../../../../form/FormFooter';
import { BrandButton } from '../../../../buttons/BrandButton';
import { CancellationLink } from '../../../../links/CancellationLink';
import { DefaultLink } from '../../../../links/DefaultLink';
import { useDetailsLoader } from './useDetailsLoader';

const t = (key, translationNamespace) =>
  I18n.t(`${translationNamespace}.new_edit.modals.compound.${key}`);

export const Form = props => {
  const {
    handleSubmit,
    compoundI18nPrefix,
    setFieldValue,
    isSubmitting,
    translationNamespace,
    addableCompoundsApi,
    addableCompoundsApiIndexParams,
    values: { cid, presentationMode, hideModal, pub_chem_url },
  } = props;

  const loading = useDetailsLoader(props);

  return (
    <form>
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          resourceName: I18n.t('resource_names.compound'),
          labelI18nKeyPrefix: `activerecord.attributes.${compoundI18nPrefix}`,
          displayMode: fieldDisplayModes.WIDE_FORM_ROW,
        }}
      >
        <ModalSection>
          {presentationMode ? (
            <FastField name="name" component={TextField} disabled />
          ) : (
            <FastField
              name="auxiliarySelector"
              label={I18n.t(`activerecord.attributes.${compoundI18nPrefix}.name`)}
              component={AsyncSelectorField}
              api={addableCompoundsApi.index}
              apiParams={addableCompoundsApiIndexParams}
              optionRenderer={htmlOptionRenderer('text')}
              onChange={async (event, onChange) => {
                await setFieldValue('cid', event?.data?.cid);
                await onChange(event);
              }}
            />
          )}
          {loading && <Loader />}
          {isDefined(cid) && !loading && <PresentationFields {...props} />}
          <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
            <FormFooterLeftPart>
              <DefaultLink href={pub_chem_url} target="_blank" hidden={!isDefined(pub_chem_url)}>
                {t('show_in_pub_chem', translationNamespace)}
              </DefaultLink>
            </FormFooterLeftPart>
            <FormFooterRightPart>
              <CancellationLink hidden={presentationMode} onClick={hideModal} />
              <BrandButton
                hidden={presentationMode}
                onClick={e => defaultHandleSubmit(setFieldValue, handleSubmit, e)}
              >
                {t('add.confirm', translationNamespace)}
              </BrandButton>
            </FormFooterRightPart>
          </FormFooter>
        </ModalSection>
      </DefaultAttributesGroup>
    </form>
  );
};
