export const setFormValuesBasedOnPchl = (productAttrs, setFieldValue) => {
  Object.entries(productAttrs.product_attributes).forEach(([key, value]) => {
    setFieldValue(key, value);
  });
  setFieldValue('pchlManufacturer', productAttrs?.manufacturer?.plain_text);
  setFieldValue('pchlStorageGroup', productAttrs?.storage_group?.plain_text);
  setFieldValue(
    'pchlHhopTypes',
    productAttrs?.hhop_types?.map(({ plain_text }) => plain_text).join('; '),
  );
};

export const setRecordType = (recordTypeValue, setFieldValue) => {
  const translatedRecordType = I18n.t(
    `activerecord.attributes.chemical_definition.record_types.${recordTypeValue}`,
  );
  setFieldValue('record_type', translatedRecordType);
};
