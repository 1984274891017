import { withFormik } from 'formik';
import {
  prepareFormFunctions,
  showSuccessMessage,
  showBackendErrorMessage,
} from '../../../../../services/utils';
import { Form } from './Form';

const t = (key, translationNamespace) =>
  I18n.t(`${translationNamespace}.new_edit.modals.compound.${key}`);

export const CompoundFunctions = prepareFormFunctions({
  handleSubmit: async ({
    id,
    listRef,
    hideModal,
    translationNamespace,
    createProductsCompoundApi,
    getCreateProductsCompoundApiParams,
  }) => {
    const { ok, data } = await createProductsCompoundApi(getCreateProductsCompoundApiParams(id));
    if (ok) {
      showSuccessMessage(t('flash.success.add', translationNamespace));
      await listRef.current.refresh();
    } else {
      showBackendErrorMessage(t('flash.errors.add', translationNamespace), data);
    }
    hideModal();
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: ({
    id,
    hide,
    listRef,
    translationNamespace,
    createProductsCompoundApi,
    getCreateProductsCompoundApiParams,
  }) => ({
    hideModal: hide,
    presentationMode: !!id,
    listRef,
    translationNamespace,
    createProductsCompoundApi,
    getCreateProductsCompoundApiParams,
    backendValuesWhitelist: CompoundFunctions.backendValuesWhitelist,
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'name',
    'cas',
    'cid',
    'ghs_codes',
    'ghs_codes_properties',
    'nfpa_diamond_labels',
    'nfpa_diamond_code',
    'nfpa_health_hazard',
    'nfpa_flammability_hazard',
    'nfpa_instability_hazard',
    'nfpa_special_hazard',
    'pub_chem_url',
  ],
});

export const CompoundModalContent = withFormik({
  mapPropsToValues: ({
    hide,
    listRef,
    compound,
    translationNamespace,
    createProductsCompoundApi,
    getCreateProductsCompoundApiParams,
  }) =>
    CompoundFunctions.prepareInitialValues({
      hide,
      listRef,
      translationNamespace,
      createProductsCompoundApi,
      getCreateProductsCompoundApiParams,
      ...(compound || {}),
    }),
  handleSubmit: CompoundFunctions.handleSubmit,
})(Form);
