import { withFormik } from 'formik';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { Form } from './Form';
import { validationSchema } from './validationSchema';

const t = key => I18n.t(`chemical_safety.chemical_libraries.chemical_definitions.new_edit.${key}`);

const ChemicalDefinitionFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const chemicalDefinition = ChemicalDefinitionFunctions.prepareValuesToSubmit({ ...values });
    ChemicalDefinitionFunctions[isDefined(chemicalDefinition.id) ? 'update' : 'create'](
      chemicalDefinition,
      formikProps,
      values.submitType,
      values.afterSave,
    );
  },
  create: async (chemicalDefinition, formikProps, submitType, afterSave) => {
    const { ok, data } = await API.chemicalSafety.chemicalDefinitions.create({
      chemical_definition: chemicalDefinition,
      submit_type: submitType,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/chemical_safety/chemical_definitions/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(t('title.edit'));
      formikProps.resetForm({
        values: ChemicalDefinitionFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      formikProps.setFieldValue('submitType', 'save');
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (chemicalDefinition, formikProps, submitType, afterSave) => {
    const { ok, data } = await API.chemicalSafety.chemicalDefinitions.update(
      chemicalDefinition.id,
      { chemical_definition: chemicalDefinition, submit_type: submitType },
    );
    if (ok) {
      formikProps.resetForm({
        values: ChemicalDefinitionFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      formikProps.setFieldValue('submitType', 'save');
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  auxiliaryValues: {
    submitType: 'save',
    pchlManufacturer: null,
    pchlStorageGroup: null,
    pchlHhopTypes: [],
    afterSave: () => {},
  },
  backendValuesWhitelist: [
    'id',
    'identifier',
    'record_type',
    'name',
    'pchl_product_id',
    'pub_chem_url',
    'chemical_storage_group_id',
    'chemical_manufacturer_id',
    'is_controlled_substance',
    'ghs_codes',
    'ghs_codes_properties',
    'explosive',
    'flammable',
    'oxidiser',
    'compressed_gas',
    'corrosive',
    'acute_toxic',
    'irritant',
    'health_hazardous',
    'environmentally_hazardous',
    'is_narcotic',
    'schedule',
    'reporting_requirement',
    'hhop_type_ids',
    'sds_attachments',
    'chemwatch_no',
  ],
});

export const ChemicalDefinitionForm = withFormik({
  mapPropsToValues: props =>
    ChemicalDefinitionFunctions.prepareInitialValues(props.chemicalDefinition),
  handleSubmit: ChemicalDefinitionFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
