import React from 'react';
import { FastField } from 'formik';
import { NfpaDiamond } from '../../NfpaProperties/NfpaDiamond';
import { FormSectionTitle } from '../../../../form/FormSectionTitle';
import { ReadOnlyGhsCodesProperties } from '../../GhsCodesProperties/ReadOnlyGhsCodesProperties';
import { TextField } from '../../../../fields/TextField';
import { DefaultAttributesGroup } from '../../../../form/DefaultAttributesGroup';
import { fieldDisplayModes } from '../../../../../constants/fieldDisplayModes';

const t = (key, translationNamespace) =>
  I18n.t(`${translationNamespace}.new_edit.modals.compound.${key}`);

export const PresentationFields = props => {
  const {
    compoundI18nPrefix,
    translationNamespace,
    values: { ghs_codes_properties, nfpa_diamond_labels, nfpa_diamond_code },
  } = props;

  return (
    <DefaultAttributesGroup
      defaultChildrenAttributes={{
        resourceName: I18n.t('resource_names.compound'),
        labelI18nKeyPrefix: `activerecord.attributes.${compoundI18nPrefix}`,
        displayMode: fieldDisplayModes.WIDE_FORM_ROW,
      }}
    >
      <FastField name="cas" component={TextField} disabled />
      <FormSectionTitle>{t('sections.ghs_classification', translationNamespace)}</FormSectionTitle>
      <FastField name="ghs_codes" component={TextField} disabled />
      <ReadOnlyGhsCodesProperties
        ghsCodesProperties={ghs_codes_properties}
        compoundI18nPrefix={compoundI18nPrefix}
      />
      <FormSectionTitle>
        {t('sections.nfpa_hazard_classification', translationNamespace)}
      </FormSectionTitle>
      {nfpa_diamond_labels && (
        <NfpaDiamond
          healthHazard={nfpa_diamond_labels.nfpa_health_hazard}
          flammabilityHazard={nfpa_diamond_labels.nfpa_flammability_hazard}
          instabilityHazard={nfpa_diamond_labels.nfpa_instability_hazard}
          specialHazard={nfpa_diamond_labels.nfpa_special_hazard}
          nfpaDiamondCode={nfpa_diamond_code}
        />
      )}
      <FastField name="nfpa_health_hazard" component={TextField} disabled />
      <FastField name="nfpa_flammability_hazard" component={TextField} disabled />
      <FastField name="nfpa_instability_hazard" component={TextField} disabled />
      <FastField name="nfpa_special_hazard" component={TextField} disabled />
    </DefaultAttributesGroup>
  );
};
